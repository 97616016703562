<template>
  <div>
    <el-button type="primary" @click="addshebei(1)">新增设备厂家</el-button>
    <el-table
      :data="tableData.data"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%;margin-top:20px"
      max-height="calc(100vh - 160px-42px)"
    >
      <el-table-column prop="name" label="厂家名称"> </el-table-column>
      <el-table-column prop="head" label="签收负责人"> </el-table-column>
      <el-table-column prop="mobile" label="联系方式"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
      <el-table-column prop="device_category" label="设备类型">
        <template slot-scope="scope">
          <span v-if="scope.row.device_category * 1 === 1">电子秤</span>
          <span v-else-if="scope.row.device_category * 1 === 2">商户屏</span>
          <span v-else-if="scope.row.device_category * 1 === 3">触摸屏</span>
          <span v-else>可视化展示屏</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">禁用</span>
          <span v-if="scope.row.status == 1">启用</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="addshebei(2, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="labtitle"
      @close="close"
      :visible.sync="add"
      width="33%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="厂家名称" prop="name">
          <el-input
            placeholder="请填写厂家名称"
            style="width:217px"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="head">
          <el-input
            placeholder="请填写负责人"
            style="width:217px"
            v-model="ruleForm.head"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系方式" prop="mobile">
          <el-input
            placeholder="请填写负责人联系方式"
            style="width:217px"
            v-model="ruleForm.mobile"
          ></el-input>
        </el-form-item>

        <el-form-item label="地址" prop="address">
          <el-input
            placeholder="请填写地址"
            style="width:217px"
            v-model="ruleForm.address"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="device_category">
          <el-select v-model="ruleForm.device_category" placeholder="请选择">
            <el-option label="电子秤" value="1"> </el-option>
            <el-option label="商户屏" value="2"> </el-option>
            <el-option label="触摸屏" value="3"> </el-option>
            <el-option label="可视化展示屏" value="4"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio label="0">禁用</el-radio>
            <el-radio label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  deviceManu,
  POSTdeviceManu,
  PUTdeviceManu
} from '../../../api/electronicList'
export default {
  name: 'electronicLists',
  data () {
    return {
      add: false,
      tableData: [],
      id: '', // 编辑按钮的id
      ruleForm: {
        name: '',
        head: '',
        mobile: '',
        address: '',
        status: 1,
        device_category: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写厂家名称', trigger: 'change' }
        ],
        device_category: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        head: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请填写负责人'))
              } else {
                const ref = /^[\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2}([,，][\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2})*$/
                if (ref.test(this.ruleForm.head)) {
                  callback()
                } else {
                  callback(new Error('请输入正确的负责人姓名'))
                }
              }
            },
            trigger: 'change',
            required: true
          }
        ],
        mobile: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入手机号码'))
              } else {
                const ref = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
                if (ref.test(this.ruleForm.mobile)) {
                  callback()
                } else {
                  callback(new Error('请输入正确的手机号码'))
                }
              }
            },
            trigger: 'blur',
            required: true
          }
        ],
        address: [{ required: true, message: '请填写地址', trigger: 'change' }]
      },
      labtitle: ''
    }
  },
  methods: {
    addshebei (num, row) {
      this.add = true
      if (num * 1 === 1) {
        this.labtitle = '新增设备厂家'
      } else if (num * 1 === 2) {
        this.labtitle = '编辑设备厂家'
        this.ruleForm.name = row.name
        this.ruleForm.head = row.head
        this.ruleForm.mobile = row.mobile
        this.ruleForm.address = row.address
        if (row.status === 1) {
          this.ruleForm.status = '1'
        } else {
          this.ruleForm.status = '0'
        }

        this.ruleForm.device_category = `${row.device_category}`
        this.id = row.id
      }
    },
    //  新增电子秤厂家
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm
          }
          if (!this.id) {
            this.labtitle = '新增设备厂家'
            //  添加电子秤厂家
            POSTdeviceManu(data).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.add = false
                this.ruleForm = {
                  name: '',
                  head: '',
                  mobile: '',
                  address: '',
                  status: '1',
                  device_category: ''
                }
                this.get_deviceManu()
              }
            })
          } else {
            // 修改电子秤厂家
            PUTdeviceManu(data, this.id).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.add = false
                this.close()
                this.get_deviceManu()
              }
            })
          }
        } else {
          return false
        }
      })
    },
    close () {
      this.add = false
      this.ruleForm = {
        name: '',
        head: '',
        mobile: '',
        address: '',
        status: '1',
        device_category: ''
      }
      this.id = ''
    },
    // 编辑按钮

    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.get_deviceManu({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.get_deviceManu({ page: val })
    },
    // 表格数据
    get_deviceManu ({ page = '', limit = '' } = {}) {
      deviceManu({ page, limit }).then(res => {
        this.tableData = res.data
      })
    }
  },
  mounted () {
    this.get_deviceManu()
  }
}
</script>

<style lang="less" scoped>
.footers {
  text-align: right;
  margin-top: 10px;
}
</style>
